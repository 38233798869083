<template>
  <section v-if="$store.state.authenticated" class="padded bordered narrow main container">
    <template v-if="stepIdx === null">
      <h1>
        <translate translate-context="*/Title">Welcome on Alta Call TSM community's space!</translate>
      </h1>
      <div
        class="stackable row"
      >
        <div class="seven wide px-regular">
          <p class="my-large">
            <translate translate-context="*/*/Form.Message">
              It looks like you're signing up for the first time. Would you like to take a 5-minutes tour to get you started? 
            </translate>
          </p>
          <button class="mr-regular" @click.prevent="stepIdx = 0">
            <translate translate-context="*/*/*">
              Yes, please
            </translate>
          </button>
          <a href="" @click.prevent="endTour"><translate translate-context="*/*/*">No, thank you</translate></a>
        </div>
        <div class="five wide">
          <img
            class="small-width"
            :src="`${publicPath}illustrations/Rocket Launch_Two Color.svg`"
            alt="Rocket launch illustration">
        </div>
      </div>
    </template>
    <template v-if="stepIdx != null">
      <div v-if="currentStep === 'account'">
        <api-form
          endpoint="profile"
          :obj="profile"
          @save="$store.commit('user', $event); stepIdx += 1; profile = $event"
          createMethod="patch"
        >
          <template v-slot:header>
            <div class="controls">
              <h1>
                <translate translate-context="*/*/*">Check account information</translate>
              </h1>
              <span>{{ progress }}</span>
            </div>
            <hr>
          </template>
          <template v-slot:form-body="slotProps">
            <p>
              <translate translate-context="*/*/*">
                Take a moment to verify your info is accurate.
              </translate>
            </p>
            <div class="stackable row">
              <div class="required field">
                <label for="profile-first-name">
                  <translate translate-context="*/*/*">
                    First name
                  </translate>    
                </label>
                <field-errors :errors="slotProps.errors.fieldErrors" field="first_name" />
                <input
                  id="profile-first-name"
                  name="profile-first-name"
                  type="text"
                  required
                  v-model="slotProps.data.first_name"
                >
              </div>

              <div class="field">
                <label for="profile-last-name">
                  <translate translate-context="*/*/*">
                    Last name
                  </translate>    
                </label>
                <field-errors :errors="slotProps.errors.fieldErrors" field="last_name" />
                <input
                  id="profile-last-name"
                  name="profile-last-name"
                  type="text"
                  v-model="slotProps.data.last_name"
                >
              </div>
            </div>
          </template>
        </api-form>

      </div>
      <div v-if="currentStep === 'download'">
        <div class="controls">
          <h1>
            <translate translate-context="*/*/*">Install Vmocall TSM</translate>
          </h1>
          <span>{{ progress }}</span>
        </div>
        <hr>
        <p>
          <translate translate-context="*/*/*">
            Ask for the <a href="mailto:sarah.boujendar@tsm-education.fr">Vmocall TSM’s installer</a> and execute it. When asked for a license key, move on to the next step.
          </translate>
        </p>
        <p>
          <translate translate-context="*/*/*">
            If you encounter any issue, you can check out our more detailed installation guide.
          </translate>
        </p>
        <router-link to="/help/docs">
          <translate translate-context="*/*/*/Action">Browse our documentation</translate>
        </router-link>
        <hr class="hidden">
        <hr class="hidden">
      </div>
      <div v-if="currentStep === 'license'">
        <div class="controls">
          <h1>
            <translate translate-context="*/*/*">Get a license key</translate>
          </h1>
          <span>{{ progress }}</span>
        </div>
        <hr>
        <p>
          <translate translate-context="*/*/*">
            Vmocall TSM needs a license key to run. You can generate a new one or contact one of your team members to share theirs.
          </translate>
        </p>
        <p>
          <translate translate-context="*/*/*">
            Once you have a valid license key, simply fill it in in Vmocall TSM to complete the installation process.
          </translate>
        </p>
        <hr class="hidden">
        <api-form
          v-if="isGroupAdmin($store.state.selectedGroup, $store.state.user)"
          endpoint="it/license-keys"
          :additionalData="{group: $store.state.selectedGroupId}"
          :obj="licenseKey"
        >
          <template v-slot:header>
            <h2>
              <translate translate-context="*/Button/Verb">Generate a new license key</translate>
            </h2>
          </template>
          <template v-slot:success-message="slotProps">
            <p>
              <translate translate-context="*/*/Form.Message">
                Your license key was successfully created.
              </translate>
              <strong>
                <translate translate-context="*/*/Form.Message">
                  Please copy it from the field below and store it in a safe place. You won't be able to see it again.
                </translate>
              </strong>
            </p>
            <input
              type="text"
              :value="slotProps.data.key"
              style="width: 100%"
              readonly>
          </template>
          <template v-slot:form-body="slotProps">
            <div class="field">
              <label for="name">
                <translate translate-context="*/*/*">Name</translate>
              </label>
              <field-errors :errors="slotProps.errors.fieldErrors" field="name" />
              <input
                required
                type="text"
                id="name"
                name="name"
                v-model="slotProps.data.name">
            </div>
          </template>
        </api-form>

        <h2>
          <translate translate-context="*/*/*">
            Existing license keys
          </translate>
        </h2>
        <loading-area
          class="opaque padded bordered"
          v-if="licenseKeys === null"
          @loaded="licenseKeys = $event.results"
          apiUrl="it/license-keys"
          :apiParams="{group: $store.state.selectedGroupId}"
        >
          <span><translate translate-context="*/*/Loader">Loading license keys…</translate></span>
        </loading-area>
        <empty-state v-else-if="licenseKeys.length === 0"></empty-state>
        <table v-else>
          <thead>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Name
              </translate>
            </th>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Key
              </translate>
            </th>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Owner
              </translate>
            </th>
          </thead>
          <tbody>
            <tr v-for="l in licenseKeys.slice(0, 5)" :key="l.id">
              <td>{{ l.name }}</td>
              <td class="monospace">{{ l.key }}</td>
              <td>
                <template v-if="l.created_by">
                  {{ l.created_by.email}}
                </template>
                <template v-else>
                  N/A
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="currentStep === 'docs'">
        <div class="controls">
          <h1>
            <translate translate-context="*/*/*">That's it!</translate>
          </h1>
          <span>{{ progress }}</span>
        </div>
        <hr>
        <p>
          <strong>
            <translate translate-context="*/*/*">
              Vmocall TSM is now configured! Please leave it running in the background in the upcoming days. 
            </translate>
          </strong>
        </p>
        <p>
          <translate translate-context="*/*/*">
            As soon as we have gathered enough data to fine-tune our transformations to your voice, it will automatically enhance your calls. This process takes at most a couple days.
          </translate>
        </p>
        <button @click.prevent="endTour">
          <translate translate-context="*/*/*">End the tour</translate>
        </button>
        <h2>
          <translate translate-context="*/*/*">Going further</translate>
        </h2>
        <p>
          <translate translate-context="*/*/*">
            Here are a few resources you can check out to get the most of Alta Call TSM:
          </translate>
        </p>
        <ul>
          <li>
            <router-link :to="{name: 'dashboard.group', params: {group: $store.state.selectedGroupId}}">
              <translate translate-context="*/*/*">Invite new team members</translate>
            </router-link>
          </li>
          <li>
             <router-link :to="{name: 'dashboard.users', params: {group: $store.state.selectedGroupId}}">
                <translate translate-context="*/*/*">Monitor Vmocall TSM usage in your team</translate>
              </router-link>
          </li>
        </ul>
        <h2>
          <translate translate-context="*/*/*">Getting help</translate>
        </h2>
        <p>
          <translate translate-context="*/*/*">
            If you face any issue, don't hesitate to check our FAQ, documentation or get in touch with our support team.
          </translate>
        </p>
        <router-link class="button" to="/help">
          <translate translate-context="*/*/*">Support and help center</translate>
        </router-link>
      </div>
      <hr class="hidden mt-large">
      <div class="controls">
        <button
          class="link"
          v-if="hasPrevious"
          @click.prevent="stepIdx -= 1"
        >
          <app-icon name="chevron-left"></app-icon>
          <translate translate-context="*/*/*">Previous step</translate>
        </button>
        <div v-else></div>
        <button
          class="link"
          v-if="hasNext"
          @click.prevent="stepIdx += 1"
        >
          <strong>
            <translate translate-context="*/*/*">Next step</translate>
          </strong>
          <app-icon name="chevron-right"></app-icon>
        </button>
      </div>
    </template>
  </section>
</template>

<script>
import {isGroupAdmin} from '@/utils'
import http from '@/http'


function getStepFromQuery (v) {
  if (!v) {
    return null
  }
  if (parseInt(v)) {
    return Math.max(parseInt(v) - 1, 0) 
  }
  return null
}
export default {
  data () {
    return {
      publicPath: process.env.BASE_URL,
      stepIdx: getStepFromQuery(this.$route.query.step),
      profile: {
        first_name: this.$store.state.user.first_name,
        last_name: this.$store.state.user.last_name,
      },
      versions: null,
      licenseKeys: null,
      licenseKey: {
        name: `${this.$store.state.user.first_name}'s onboarding key`
      },
      isGroupAdmin
    }
  },
  computed: {
    steps () {
      let s = [
        'account',
        'download',
        'license',
        'docs',
      ]
      if (this.$store.state.user.permissions.client_admin) {
        // s.unshift('organization')
      }
      return s
    },
    currentStep () {
      return this.steps[this.stepIdx]
    },
    hasPrevious () {
      return this.stepIdx > 0
    },
    hasNext () {
      return this.stepIdx < this.steps.length - 1
    },
    progress () {
      let message = this.$pgettext("*/*/Progress", "Step %{ current } on %{ total }")
      return this.$gettextInterpolate(message, {current: this.stepIdx + 1, total: this.steps.length})
    }
  },
  methods: {
    async endTour () {
      let response = await http.patch(`profile`, {onboarding_complete: true})
      this.$store.commit("user", response.data)
      this.$router.push('/')
    }
  },
  watch: {
    stepIdx (v) {
      if (v != null) {
        this.$router.push({query: {step: v + 1}})
      }
    },
    "$route.query.step": function (v) {
      this.stepIdx = getStepFromQuery(v)
    }
  }
}
</script>
